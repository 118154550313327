<template>
  <div style="background: #f7f7fb">
    <!-- pc -->
    <div class="wholeBox hidden-xs-only flex j_s">
      <div class="wholeLeft">
        <h2 class="title boxBg">{{ sortChoseTitle }}</h2>

        <ul class="boxBg">
          <Loading v-show="isTxtListHttp"></Loading>
          <li
            class="flex a_c"
            v-for="(item, i) in txtList"
            :key="i"
            @click="linktoTxtInfo(item.id)"
          >
            <div style="width: 210px; height: 140px; margin-right: 20px">
              <img :src="item.img" alt="" />
            </div>

            <div>
              <div class="textTitle">
                {{ item.title }}
              </div>
              <div class="textCon">
                {{ item.desc }}
              </div>
              <div class="textRem">
                {{ item.author }} <span>{{ item.update_at }}</span>
              </div>
            </div>
          </li>
          <el-pagination
            :page-size="5"
            :hide-on-single-page="true"
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="total"
          >
          </el-pagination>
          <div class="listNull" v-if="txtList.length == 0 && !isTxtListHttp">
            暂无相关文章。。。
          </div>
        </ul>
      </div>
      <div class="wholeRight">
        <div class="sortBox boxBg">
          <div class="sortTitle flex a_c">
            <img
              src="https://static.5t1800.com/s3/2023/05/16/64631e6fc202d.png"
              alt=""
            />
            <span> 文章分类 </span>
          </div>
          <Loading v-show="isSortListHttp"></Loading>
          <ul>
            <li
              @click="changeSort(i)"
              v-for="(item, i) in sortList"
              :key="i"
              :class="sortChoseId == item.id ? 'on' : ''"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="txtBox boxBg">
          <div class="sortTitle flex a_c">
            <img
              src="https://static.5t1800.com/s3/2023/05/16/64631e6fc202d.png"
              alt=""
            />
            <span> 文章推荐</span>
          </div>
          <div class="txtListBox">
            <Loading v-show="isRecommendListHttp"></Loading>
            <div
              class="listNull"
              v-if="recommendList.length == 0 && !isRecommendListHttp"
            >
              暂无相关文章。。。
            </div>
            <div
              v-for="(item, i) in recommendList"
              :key="i"
              class="flex"
              @click="linktoTxtInfo(item.id)"
            >
              <img :src="item.img" alt="" />
              <div>
                <div class="txtTitle">
                  {{ item.title }}
                </div>
                <div class="txtTime">{{ item.update_at }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- H5 -->
    <div class="wholeBoxH5 hidden-sm-and-up">
      <Loading v-show="isSortListHttp"></Loading>
      <ul class="sortBox boxBg flex a_c">
        <li @click="changeSort(i)" v-for="(item, i) in sortList" :key="i">
          <img :src="item.img_url" alt="" />
          <div :class="sortChoseId == item.id ? 'on' : ''">
            {{ item.name }}
          </div>
        </li>
      </ul>
      <ul style="padding-bottom:8px;">
        <Loading v-show="isTxtListHttp"></Loading>
        <li
          class="txtBox boxBg flex a_c boxBg"
          v-for="(item, i) in txtList"
          :key="i"
          @click="linktoTxtInfo(item.id)"
        >
          <div style="width: 118px; height: 90px; margin-right: 10px">
            <img :src="item.img" alt="" />
          </div>
          <div>
            <div class="textTitle">
              {{ item.title }}
            </div>
            <div class="textRem">
              {{ item.author }} <span>{{ item.update_at }}</span>
            </div>
          </div>
        </li>
        <div class="listNull" v-if="txtList.length == 0 && !isTxtListHttp">
          暂无相关文章。。。
        </div>
        <van-pagination v-if="txtList.length != 0 && !isTxtListHttp" v-model="currentPage" @change="handleCurrentChange" :items-per-page="5" :total-items="total" mode="simple" />
      </ul>
      <div class="sortTitle boxBg">
        <span> 文章推荐</span>
      </div>
      <div class="txtListBox">
        <Loading v-show="isRecommendListHttp"></Loading>
        <div
          class="listNull"
          v-if="recommendList.length == 0 && !isRecommendListHttp"
        >
          暂无相关文章。。。
        </div>
        <li
          class="txtBox boxBg flex a_c boxBg"
          v-for="(item, i) in recommendList"
          :key="i"
          @click="linktoTxtInfo(item.id)"
        >
          <div style="width: 118px; height: 90px; margin-right: 10px">
            <img :src="item.img" alt="" />
          </div>
          <div>
            <div class="textTitle">
              {{ item.title }}
            </div>
            <div class="textRem">
              {{ item.author }} <span>{{ item.update_at }}</span>
            </div>
          </div>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import url from "../../../utils/url";
import Loading from "../../../components/loading.vue";
import Vue from "vue";
import { Pagination } from "vant";

Vue.use(Pagination);
export default {
  data() {
    return {
      sortList: [], //分类列表
      txtList: [], //文章列表
      recommendList: [], //热门文章列表
      total: 0, //文章总数
      sortChoseId: "",
      sortChoseTitle: "",
      isTxtListHttp: false, //文章加载状态
      isSortListHttp: false, //分类加载状态
      isRecommendListHttp: false, //热门文章加载状态
      currentPage:1,//当前页码
    };
  },
  metaInfo: {
    title: "Vinistyle 薇妮", // set a title
    meta: [
      {
        name: "keyWords",
        content: "薇妮vinistyle,薇妮,薇妮美肤,薇尼美肤空间,护肤品,化妆品加盟,薇妮化妆品加盟,化妆品,薇妮化妆品,美白,防晒,防晒霜",
      },
      {
        name: "description",
        content: "vinistyle薇妮是江苏苏美国际旗下化妆品品牌，主营功效性护肤产品和解决方案，包括美容院专业线套组以及居家护肤产品，针对用户常见的斑、痘、敏、皱四大类肌肤问题，都有针对性的产品和解决方案。vinistyle薇妮提供整店输出、产品合作、项目合作等各种合作方式，诚邀美容院、皮肤管理工作、化妆品实体店加盟合作，共创美丽事业！",
      },
    ],
  },
  components: {
    Loading,
  },
  created() {
    this.$store.commit("saveTypeIndex", 5);
    this.getSortList(); //获取分类列表
    this.getSortTxtList("", 100, "", 1); //获取热门文章
    console.log(url.BASE_URL);
  },
  mounted() {},
  methods: {
    //获取分类列表
    getSortList() {
      let _self = this;
      _self.isSortListHttp = true;
      axios({
        method: "get",
        url: `/api/news/getNewSort`,
        params: {
          pageSize: 100,
        },
      })
        .then((res) => {
          console.log(res);
          _self.isSortListHttp = false;
          _self.sortList = res.data.rows;

          _self.sortChoseId = res.data.rows[0].id;
          _self.sortChoseTitle = res.data.rows[0].name;
          _self.getSortTxtList(res.data.rows[0].id);
        })
        .catch((err) => {
          _self.isSortListHttp = false;
          console.log(err);
        });
    },
    //获取分类下的所有文章
    getSortTxtList(id, pageSize = 5, pageNumber = 1, is_hot = 0) {
      let _self = this;
      is_hot == 1
        ? (_self.isRecommendListHttp = true)
        : (_self.isTxtListHttp = true);
      axios({
        method: "get",
        url: `/api/news/getNewsInformation`,
        params: {
          category_id: id,
          pageSize,
          pageNumber,
          is_hot,
        },
      })
        .then((res) => {
          _self.isRecommendListHttp = false;
          _self.isTxtListHttp = false;
          console.log(res.data.rows);
          if (is_hot == 1) {
            _self.recommendList = res.data.rows;
          } else {
            _self.total = Number(res.data.total);
            console.log(_self.total);
            _self.txtList = res.data.rows;
          }
        })
        .catch((err) => {
          _self.isRecommendListHttp = false;
          _self.isTxtListHttp = false;
          console.log(err);
        });
    },
    //切换分类
    changeSort(i) {
      if (!this.isTxtListHttp) {
        this.total = 0;
        this.txtList = [];
        this.sortChoseId = this.sortList[i].id;
        this.sortChoseTitle = this.sortList[i].name;
        this.getSortTxtList(this.sortList[i].id);
      }
    },
    //切换页数
    handleCurrentChange(e) {
      this.txtList = [];
      this.getSortTxtList(this.sortChoseId, 5, e);
      console.log(`当前页: ${e}`);
    },
    //跳转到文章详情
    linktoTxtInfo(id) {
      let { href } = this.$router.resolve({
        name: "newsInfo",
        params: { id: id },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped>
.listNull {
  padding: 20px;
  text-align: center;
  color: #999;
}
.wholeBox {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}
.wholeBox .wholeLeft {
  width: 855px;
}

.wholeBox .wholeRight {
  width: 330px;
}
.wholeBox .boxBg {
  background: #fff;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 9px -3px rgb(10 10 10 / 3%);
}
.wholeBox .wholeLeft ul {
  min-height: 1000px;
}
.wholeBox .wholeLeft .title {
  height: 36px;
  line-height: 36px;
  padding: 15px 20px;
  margin-bottom: 20px;
  margin-top: 0;
}
.wholeBox .wholeLeft li {
  box-sizing: border-box;
  padding: 20px;
  border-bottom: 1px solid #f7f7fb;
  cursor: pointer;
}
.wholeBox .wholeLeft ul li img {
  display: block;
  width: 210px;
  height: 140px;
  border-radius: 4px;
}
.wholeBox .wholeLeft li .textTitle {
  min-height: 30px;
  margin-bottom: 8px;
  font-size: 18px;
  color: #18191a;
  font-weight: 700;
}
.wholeBox .wholeLeft li .textTitle:hover {
  color: #f19dad;
}
.wholeBox .wholeLeft li .textCon {
  height: 66px;
  line-height: 22px;
  font-size: 14px;
  color: #88909d;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wholeBox .wholeLeft li .textRem {
  margin-top: 12px;
  font-size: 12px;
  color: #88909d;
}
.wholeBox .wholeLeft li .textRem span {
  margin-left: 10px;
}

.wholeRight .sortBox {
  padding: 0 20px;
}

.wholeRight .sortTitle {
  padding: 20px 0 10px 0;
  font-size: 18px;
  color: #20232b;
  font-weight: 700;
}
.wholeRight .sortTitle img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}
.wholeRight ul {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.wholeRight ul li {
  height: 20px;
  line-height: 20px;
  padding: 5px 15px;
  border: 1px solid #f19dad;
  white-space: nowrap;
  border-radius: 4px;
  overflow: hidden;
  line-height: 20px;
  font-size: 14px;
  color: #f19dad;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.wholeRight ul li:hover {
  background: #f19dad;
  color: white;
}
.wholeRight ul .on {
  background: #f19dad;
  color: white;
}

.wholeRight .txtBox {
  padding: 0 20px;
  margin-top: 20px;
  padding-bottom: 5px;
}
.wholeRight .txtListBox > div {
  margin-bottom: 15px;
  cursor: pointer;
}
.wholeRight .txtListBox img {
  width: 110px;
  height: 85px;
  border-radius: 2px;
  margin-right: 15px;
}
.wholeRight .txtTitle {
  font-size: 14px;
  color: #18191a;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wholeRight .txtTitle:hover {
  color: #f19dad;
}
.wholeRight .txtTime {
  padding-top: 8px;
  font-size: 10px;
  color: #88909d;
}
/deep/.el-pager li.active {
  color: #f19dad;
  cursor: default;
}

.wholeBoxH5 {
  margin: 0 auto;
  padding: 8px 0;
}
.wholeBoxH5 .boxBg {
  background: #fff;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 9px -3px rgb(10 10 10 / 3%);
  margin-bottom: 8px;
}
.wholeBoxH5 .sortBox {
  padding: 8px 0;
}
.wholeBoxH5 .sortBox li {
  padding: 0 8px;
}
.wholeBoxH5 .sortBox img {
  width: 42px;
  height: 42px;
  display: block;
  margin: 0 auto;
}
.wholeBoxH5 .sortBox div {
  padding-top: 3px;
  font-size: 14px;
  color: #4c4c4c;
}
.wholeBoxH5 .sortBox li .on {
  color: #f19dad;
}
.wholeBoxH5 .txtBox {
  padding: 8px;
  box-sizing: border-box;
}
.wholeBoxH5 .txtBox img {
  width: 118px;
  height: 90px;
}
.wholeBoxH5 .textTitle {
  color: #131922;
  height: 66px;
  line-height: 22px;
  font-size: 16px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wholeBoxH5 .textRem {
  font-size: 12px;
  color: #222831;
}
.wholeBoxH5 .sortTitle {
  padding: 8px;
  font-weight: bold;
}
/deep/.van-pagination__item {
    color: #f19dad;
}
</style>

